<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="layout-row cols">
        <div class="col-1 flex-noshrink layout-column layout-align-start-end">
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative"
            :show="showTitle"
          ></section-title>

          <div
            class="
              diagram-buttons
              layout-row layout-wrap
              parallax-item parallax-flex
            "
            data-depth="1.5"
          >
            <div
              class="button-wrap"
              v-for="(item, i) in diagram.legend"
              :key="i"
              :data-item="i"
            >
              <button
                class="btn btn-control crosshair"
                :class="{
                  active: itemStatus.legend.active.includes(i),
                  inactive: itemStatus.legend.inactive.includes(i),
                }"
                @mouseover="legendHover(i)"
                @mouseleave="legendHover(-1)"
                @click="legendClick(i)"
                :style="{ background: item.color }"
              >
                <i
                  :class="`ai-${item.name}`"
                  :style="{ color: item.color }"
                ></i>
                {{ spaceForDash(item.name) }}
              </button>
            </div>
          </div>

          <info-modal-box
            :show="showInfoBoxes"
            class="parallax-item parallax-relative section-infobox"
            data-depth="3.8"
            :data="data.boxes.info_1"
          >
          </info-modal-box>
        </div>
        <div class="col-2 layout-column flex-grow layout-align-center-center">
          <div class="diagram-scroll-indicator">
            <div class="swipe-icon"></div>
          </div>
          <diagram-village
            :data="diagram"
            :iconColors="iconColors"
            :itemStatus="itemStatus.items"
            :class="legendFilter"
            @item-hover="itemHover"
            @item-click="itemClick"
            :show="showDiagram"
            v-if="iconColors"
          ></diagram-village>
        </div>
      </div>
    </div>

    <decal
      class="absolute bottom-left"
      :show="showDecal"
      :parallaxDepth="1"
      :image="data.images.decal"
    ></decal>

    <modal-main
      v-for="modal in data.modals"
      :data="modal"
      :key="modal.name"
    ></modal-main>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Decal from "@/components/ui/Decal.vue";
import InfoModalBox from "@/components/ui/InfoModalBox.vue";

import DiagramVillage from "@/views/chapters/1/DiagramVillage.vue";
import ModalMain from "@/modals/ModalMain.vue";

export default {
  name: "Chapter-1-2",
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Decal,
    InfoModalBox,
    DiagramVillage,
    ModalMain,
  },
  data() {
    return {
      doParallax: true,
      showControls: false,
      timeline: null,
      timescale: 1,
      timescaleReverse: 1.5,
      itemClicked: false,
      itemsToShow: [
        ["showDelay", 500],
        ["showTitle", 700],
        ["showDiagram", 0],
        ["showDecal", 600],
        ["showControls", 100],
        ["showInfoBoxes", 800],
      ],
      itemStatus: {
        items: {
          active: [],
          inactive: [],
        },
        legend: {
          active: [],
          inactive: [],
        },
      },
      legendFilter: "",
      diagram: {
        width: 910,
        height: 840,
        items: [
          {
            name: "Water Treatment Plant",
            sub: "abundance",
            cat: ["environment", "health"],
            width: 164,
            x: 45,
            y: 72,
          },
          {
            name: "Gardens",
            sub: "harmony",
            cat: ["environment"],
            width: 176,
            x: 287,
            y: 20,
          },
          {
            name: "Beehives",
            sub: "co-creation",
            cat: ["environment"],
            width: 117,
            x: 517,
            y: 40,
          },
          {
            name: "Solar Farm",
            sub: "renewable energy",
            cat: ["environment"],
            width: 106,
            x: 689,
            y: 25,
          },
          {
            name: "Staff Village",
            sub: "oneness",
            cat: ["ubuntu", "village-life"],
            width: 200,
            x: 55,
            y: 207,
          },
          {
            name: "Tracker Academy",
            sub: "ancient wisdom",
            cat: ["education", "environment"],
            width: 111,
            x: 449,
            y: 196,
          },
          {
            name: "Recycling Center",
            sub: "respect",
            cat: ["environment"],
            width: 183,
            x: 674,
            y: 216,
          },
          {
            name: "Old Shangaan Village",
            sub: "respect of culture",
            cat: ["ubuntu", "village-life"],
            width: 259,
            x: 169,
            y: 343,
          },
          {
            name: "Spaza Café",
            sub: "atmosphere",
            cat: ["village-life"],
            width: 104,
            x: 496,
            y: 385,
          },
          {
            name: "Live Learning Lab",
            sub: "heartware",
            cat: ["education"],
            width: 168,
            x: 705,
            y: 367,
          },
          {
            name: "Food gardens",
            sub: "sustainability",
            cat: ["environment", "village-life", "health"],
            width: 149,
            x: 68,
            y: 469,
          },
          {
            name: "Ubuntu Hut",
            sub: "ubuntu",
            cat: ["ubuntu"],
            width: 112,
            x: 319,
            y: 515,
          },
          {
            name: "Creche",
            sub: "fun",
            cat: ["education"],
            width: 158,
            x: 496,
            y: 576,
          },
          {
            name: "Clinic",
            sub: "care",
            cat: ["health"],
            width: 137,
            x: 725,
            y: 536,
          },
          {
            name: "Creative Hub",
            sub: "online global movement",
            cat: ["education"],
            width: 61,
            x: 78,
            y: 657,
          },
          {
            name: "Mandela's Way",
            sub: "freedom",
            cat: ["ubuntu", "village-life"],
            width: 128,
            x: 304,
            y: 698,
          },
          {
            name: "Infrastructure",
            sub: "hardware",
            cat: ["building"],
            width: 232,
            x: 609,
            y: 696,
          },
        ],
        legend: [
          {
            name: "ubuntu",
            color: "#5b7f9f",
          },
          {
            name: "education",
            color: "#ee1e76",
          },
          {
            name: "environment",
            color: "#638d43",
          },
          {
            name: "health",
            color: "#d2232a",
          },
          {
            name: "village-life",
            color: "#00a6ca",
          },
          {
            name: "building",
            color: "#23408e",
          },
        ],
      },
    };
  },
  filters: {},
  computed: {
    legendNames() {
      return this.diagram.legend.map((item) => item.name);
    },
    legendItemMap() {
      const map = this.legendNames.map((item) => []);

      this.diagram.items.forEach((item, i) => {
        item.cat.forEach((cat) => {
          map[this.legendNames.indexOf(cat)].push(i);
        });
      });
      return map;
    },
    iconColors() {
      let colors = {};
      this.diagram.legend.forEach((item) => {
        colors[item.name] = item.color;
      });

      return colors;
    },
  },
  watch: {
    showControls(val) {
      if (this.timeline) {
        if (val) {
          this.timeline.play().timeScale(this.timescale);
        } else {
          this.timeline.reverse().timeScale(this.timescaleReverse);
        }
      }
    },
  },
  methods: {
    spaceForDash(val) {
      return val.replace(/-/g, " ");
    },
    resetItemStatus() {
      this.itemStatus.legend.active = [];
      this.itemStatus.legend.inactive = [];
      this.itemStatus.items.inactive = [];
      this.itemStatus.items.active = [];
    },
    itemHover(val) {
      if (val >= 0) {
        this.makeItemActive(val);
      } else {
        this.resetItemStatus();
      }
    },
    itemClick(val) {
      this.makeItemActive(val);
      this.activateLegendFilter(-1);

      this.itemClicked = true;

      clearTimeout(this.itemClickTimer);

      if (this.itemClicked) {
        this.itemClickTimer = setTimeout(() => {
          this.itemClickTimer = false;
          this.resetItemStatus();
        }, 2500);
      }
    },
    makeItemActive(val) {
      this.itemStatus.items.active = [val];
      this.itemStatus.items.inactive = this.diagram.items
        .map((item, i) => i)
        .filter((i) => i != val);

      this.itemStatus.legend.active = this.diagram.items[val].cat.map((key) =>
        this.legendNames.indexOf(key)
      );

      this.itemStatus.legend.inactive = this.diagram.legend
        .map((item, i) => i)
        .filter((item) => !this.itemStatus.legend.active.includes(item));
    },
    makeLegendItemActive(val) {
      //legend active and inactive
      this.itemStatus.legend.active = [val];
      this.itemStatus.legend.inactive = this.legendNames
        .filter((name, i) => i != val)
        .map((el) => this.legendNames.indexOf(el));

      //items active and inactive
      let activeItems = this.legendItemMap[val];
      this.itemStatus.items.active = activeItems;

      this.itemStatus.items.inactive = this.diagram.items
        .map((item, i) => i)
        .filter((item) => !activeItems.includes(item));
    },
    activateLegendFilter(val) {
      //set filter class
      const vm = this;
      let filterClasses =
        val >= 0 ? `filter-hover filter-${this.legendNames[val]}` : "";

      setTimeout(function () {
        vm.legendFilter = filterClasses;
      }, 300);
    },
    legendHover(val) {
      if (device.type != "desktop") {
        return;
      }

      if (val >= 0) {
        this.makeLegendItemActive(val);
      } else {
        this.resetItemStatus();
      }

      this.activateLegendFilter(val);
    },
    legendClick(val) {
      if (device.type == "desktop") {
        return;
      }
      this.makeLegendItemActive(val);
      this.activateLegendFilter(val);

      this.itemClicked = true;

      clearTimeout(this.itemClickTimer);

      if (this.itemClicked) {
        this.itemClickTimer = setTimeout(() => {
          this.itemClickTimer = false;
          this.activateLegendFilter(-1);
          this.resetItemStatus();
        }, 2500);
      }
    },
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.set(
        ".diagram-buttons",
        {
          autoAlpha: 1,
        },
        0
      ).staggerFrom(
        ".button-wrap",
        0.9,
        {
          y: "+=50",
          autoAlpha: 0,
          ease: Power2.easeInOut,
        },
        0.07,
        0
      );

      this.timeline = tl;
    },
  },
  mounted() {
    this.createTimeline();
  },
  beforeDestroy() {
    if (this.timeline) {
      this.timeline.kill();
    }
  },
};
</script>

<style scoped>
.col-1 {
  width: 36%;
}
.col-2 {
  position: relative;
}
.section-title {
  margin-top: 6.5%;
  max-width: 6em;
}
.section-scene {
  pointer-events: none !important;
}
.items-shown .section-scene {
  pointer-events: auto !important;
}
.section-decal {
  max-width: 35vw;
  left: -3%;
  width: 560px;
}
.diagram-buttons {
  margin: 0.5em 0 1.4em;
  opacity: 0;
  width: 28.75em;
}
.button-wrap {
  width: 50%;
  padding-left: 3.375em;
  max-width: 17.5em;
}
.diagram-buttons button i {
  font-size: 2.2em;
  position: absolute;
  left: -1.1em;
  top: 0;
  text-shadow: none;
  transition: 0.3s 0.2s ease-out;
}
.diagram-buttons button i.ai-education {
  font-size: 2em;
  top: 0.04em;
  left: -1.2em;
}
.diagram-buttons button:not(.active) i {
  color: var(--grey3) !important;
}
.diagram-buttons button {
  position: relative;
  padding: 0.7em 1.2em 0.46em 1.2em;
  text-align: left;
  color: white;
  text-transform: capitalize;
  font-size: 1em;
  letter-spacing: 0.07em;
  vertical-align: top;
  margin-bottom: 6px;
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.1),
    2px 3px 2px rgba(0, 0, 0, 0.15);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  transition: 0.3s 0.2s ease-out;
  width: 100%;
}
.diagram-buttons button.active {
  transform: scale(1.05);
  box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0),
    1px 2px 4px rgba(0, 0, 0, 0.1);
}

.diagram-buttons button.inactive {
  transform: scale(0.95);
  filter: grayscale(1);
  opacity: 0.3;
  transition-timing-function: ease-in;
}

/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1600px) {
  .part-diagram {
    margin-right: 0;
  }
}
@media screen and (max-width: 1440px) {
  .col-1 {
    width: auto;
    padding: var(--col-padding);
    padding-right: 0;
  }
  .col-2 {
    padding: var(--col-padding);
    padding-left: 2rem;
  }
  .section-foreground {
    padding-bottom: 4.5rem;
  }
}
@media screen and (max-width: 1366px) {
  .section-title {
    margin-top: 0.45em;
  }
  .diagram-buttons {
    font-size: 0.9rem;
  }
  .col-2 {
    padding-left: 1rem;
  }
}
@media screen and (max-width: 1200px) {
  .diagram-buttons {
    font-size: 0.8rem;
  }
  .diagram-buttons button {
    font-family: "din";
    text-transform: uppercase;
    font-size: 1.518em;
    letter-spacing: 0;
    padding: 0.3em 1em 0.36em;
  }
  .diagram-buttons button i {
    font-size: 1.4em;
    top: 0.2em;
  }
  .diagram-buttons button i.ai-education {
    font-size: 1.3em;
    top: 0.2em;
  }
  .col-1 {
    padding-left: 1.4rem;
  }
  .col-2 {
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1023px),
  screen and (min-width: 992px) and (max-width: 1024px) and (orientation: portrait) {
  .cols {
    flex-direction: column;
    position: relative;
  }
  .col-1 {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .section-foreground {
    padding-bottom: 28em;
  }
  .info-box-container {
    position: absolute !important;
    bottom: 0 !important;
    top: auto !important;
    transform: translate(-50%, 100%) !important;
    left: 50% !important;
  }
  .section-decal {
    max-width: 40vw;
  }
  .diagram-buttons {
    margin: 0 2rem 2.2em 0;
    align-self: flex-end;
  }
  .section-title {
    margin-right: 2rem;
    margin-bottom: 0.5em;
  }
}

@media screen and (max-width: 991px) {
  .section-title {
    margin-top: 0.4em;
    margin-bottom: 0.4em;
  }
  .diagram-buttons {
    margin: 0 2rem 0.6em 0;
  }
  .portrait .section-decal {
    min-width: 24rem;
  }
  .col-1 {
    padding-top: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .col-1 {
    padding-bottom: 0.5rem;
  }
  .diagram-buttons button {
    padding: 0.2em 1em 0.34em;
    margin-bottom: 4px;
  }
  .section-title {
    margin-right: 1rem;
    margin-top: 0.2em;
  }
  .diagram-buttons button i {
    top: 0.1em;
  }
  .section-foreground {
    padding-bottom: 28em;
  }
  @media screen and (max-width: 640px) {
    .info-box-container {
      transform: translate(-50%, 103%) !important;
    }
    .section-title {
      margin-bottom: 2.5rem;
    }
    .section-foreground {
      padding-bottom: 34em;
    }
  }
  @media screen and (max-width: 639px) {
    .col-1 {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: var(--col-padding);
      padding-top: 1rem;
      flex-wrap: nowrap;
    }
    .section-title,
    .diagram-buttons {
      align-self: center;
      margin-right: 0;
      margin-left: 0;
    }
    .diagram-buttons {
      margin-bottom: 1.5rem;
      margin-right: 1.8rem;
    }
    .col-2 {
      overflow: hidden;
      overflow-x: scroll;
      padding: 0.5rem 0.2rem 0;
    }
    .part-diagram {
      min-width: 600px;
      padding-right: 25px;
      align-self: flex-start;
    }
  }
  @media screen and (max-width: 605px) {
    .diagram-scroll-indicator {
      display: block;
    }
  }
}
</style>
